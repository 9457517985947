@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");

body {
  font-family: "Roboto Mono", monospace;
  font-size: 14px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
}

a {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}
