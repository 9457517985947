.hex {
  position: absolute;
  background-image: url("./hex-bg.png");
  background-size: cover;
  background-repeat: no-repeat;

  clip-path: polygon(
    0 50px,
    100px 0,
    calc(100% - 100px) 0,
    100% 50px,
    100% calc(100% - 50px),
    calc(100% - 100px) 100%,
    100px 100%,
    0 calc(100% - 50px)
  );
}

.hex-bg0 {
  background-image: url("./hex-bg-0.png");
}

.hex-bg1 {
  background-image: url("./hex-bg-1.png");
}

.hex-bg2 {
  background-image: url("./hex-bg-2.png");
}

.hex-bg3 {
  background-image: url("./hex-bg-3.png");
}

.hex-bg4 {
  background-image: url("./hex-bg-4.png");
}

.hex-bg5 {
  background-image: url("./hex-bg-5.png");
}

.hex-text {
  width: 120px;
  height: 120px;
  top: 70px;
  left: 68px;
}
