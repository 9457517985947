body {
  background-color: antiquewhite;
}

.antique {
  background-color: antiquewhite;
}

.antique-font {
  color: antiquewhite;
}

.antique-font-hover:hover {
  color: antiquewhite;
  transition: color 0.3s ease;
  background-color: black;
  opacity: 1;
}

input[type="checkbox"]:checked:not(.rainbow-checkbox) {
  filter: sepia(100%);
}

button:focus {
  outline: none;
}

button,
.button {
  border: 1px solid black;
  padding-left: 5pt;
  padding-right: 5pt;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

button:hover,
.button:hover {
  background-color: black;
  color: antiquewhite;
}

.button-blue {
  font-size: 13px;
}

.button-blue:hover {
  background-color: rgb(0, 29, 114);
  color: antiquewhite;
}

.transformHack {
  transform: scale(1);
}

.spin {
  animation: spin 2s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
}

/* Add animation for blinking red color */
@keyframes blink-red {
  0%,
  100% {
    color: inherit;
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    color: red;
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-blink-red {
  animation: blink-red 1s infinite;
}

.animate-from-fade-on-appear {
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.leftmenu-bg {
  //animate from opacity 0 to opacity 1
  animation: fade-in 0.4s ease-in-out;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  &.visible {
    opacity: 1;
  }
}

@keyframes rainbow {
  0% {
    background: #ff0000;
  }
  17% {
    background: #ff8000;
  }
  33% {
    background: #ffff00;
  }
  50% {
    background: #00ff00;
  }
  67% {
    background: #0000ff;
  }
  83% {
    background: #8000ff;
  }
  100% {
    background: #ff0000;
  }
}

@keyframes rainbow-color {
  0% {
    color: #ff0000;
  }
  17% {
    color: #ff8000;
  }
  33% {
    color: #ffff00;
  }
  50% {
    color: #00ff00;
  }
  67% {
    color: #0000ff;
  }
  83% {
    color: #8000ff;
  }
  100% {
    color: #ff0000;
  }
}

.rainbow-color {
  animation: rainbow-color 4s linear infinite;
}

.rainbow-checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  background: white;

  &:checked {
    animation: rainbow 4s linear infinite;
    border-color: transparent;
    filter: none;
  }

  &:hover {
    animation: rainbow 4s linear infinite;
    border-color: transparent;
    filter: none;
  }

  &:checked::after {
    content: "✓";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 14px;
  }
}

.rainbow-hover {
  &:hover {
    animation: rainbow 4s linear infinite;
    border-color: transparent;
    filter: none;
  }
}
